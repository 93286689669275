<template>
  <section class="l-content">
    <el-row>
      <el-col :span="12">
        <el-card class="margin-xs" :body-style="cardBodyStyle">
          <div slot="header" class="clearfix">
            <i class="el-icon-s-operation"></i>
            <span> 角色列表</span>
          </div>
          <l-table :option="tableOption" :data="dataList"> </l-table>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="margin-xs" :body-style="cardBodyStyle">
          <div slot="header" class="clearfix">
            <el-row type="flex" justify="space-between">
              <el-col
                ><i class="el-icon-s-operation"></i>
                <span> Web菜单权限</span>
              </el-col>
              <el-button v-if="selRole" type="primary" size="mini" @click="saveWebMenu">保存</el-button>
            </el-row>
          </div>
          <el-alert v-if="selRole" :title="selRole.roleName" type="success" effect="dark" :closable="false" class="margin-bottom-xs"> </el-alert>
          <el-tree v-show="selRole" :data="webMenuList" show-checkbox ref="webMenuTree" node-key="code" node-la default-expand-all :props="{ label: 'name' }"> </el-tree>
        </el-card>
      </el-col>
      <el-col :span="6" v-show="false">
        <el-card class="margin-xs" :body-style="cardBodyStyle">
          <div slot="header" class="clearfix">
            <el-row type="flex" justify="space-between">
              <el-col>
                <i class="el-icon-s-operation"></i>
                <span> App菜单权限</span>
              </el-col>
              <el-button v-if="selRole" type="primary" size="mini" @click="saveAppMenu">保存</el-button>
            </el-row>
          </div>
          <el-alert v-if="selRole" :title="selRole.roleName" type="success" effect="dark" :closable="false" class="margin-bottom-xs"> </el-alert>
          <el-tree v-show="selRole" :data="appMenuList" show-checkbox ref="appMenuTree" node-key="code" node-la default-expand-all :props="{ label: 'name' }"> </el-tree>
        </el-card>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import roleAddoredit from './role_addoredit'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'Role',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [{ label: '角色名称', prop: 'roleName' }],
        operation: {
          width: '220',
          header: { type: 'warning', icon: 'el-icon-plus', label: '新增角色', click: this.add },
          buttons: [
            { type: 'primary', icon: 'el-icon-edit', label: '', click: this.edit },
            { type: 'danger', icon: 'el-icon-delete', label: '', click: this.del },
            { type: '', icon: 'el-icon-right', label: '', click: this.setMenu }
          ]
        }
      },
      cardBodyStyle: {
        overflow: 'auto',
        height: document.documentElement.clientHeight - 160 + 'px'
      },
      dataList: null,
      selRole: null,
      webMenuList: null,
      appMenuList: null
    }
  },
  created() {
    this.getDataList()
    this.getMenuList()
  },
  methods: {
    async getDataList() {
      this.selRole = null
      this.dataList = await this.$lgh.get('api/enterprise/role/list', ['enterpriseCode'])
    },
    async add() {
      await this.$lgh.openDialog({
        ins: roleAddoredit,
        title: '新增角色信息',
        data: {
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode
          }
        }
      })
      this.$lgh.toastSuccess('新建角色信息成功')
      this.getDataList()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: roleAddoredit,
        title: '修改角色信息',
        data: {
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.toastSuccess('修改角色信息成功')
      this.getDataList()
    },
    del(item) {
      this.$lgh.msgConfirm('确认删除角色信息?', async () => {
        await this.$lgh.get('api/enterprise/role/delete', {
          enterpriseCode: item.enterpriseCode,
          roleId: item.roleId
        })
        this.$lgh.notifySuccess('删除成功', item.roleName + '信息已删除')
        this.getDataList()
      })
    },
    setMenu(item) {
      this.selRole = item
      this.$refs.webMenuTree.setCheckedKeys(item.web_menu_list)
      this.$refs.appMenuTree.setCheckedKeys(item.app_menu_list)
    },
    getMenuList() {
      this.$lgh.get('api/enterprise/webmenu/list', ['enterpriseCode'], false).then(res => {
        this.webMenuList = res
      })
      this.$lgh.get('api/enterprise/appmenu/list', ['enterpriseCode'], false).then(res => {
        this.appMenuList = res
      })
    },
    async saveWebMenu() {
      var list = this.$refs.webMenuTree.getCheckedKeys()
      await this.$lgh.post('api/enterprise/role/update_webmenu', {
        roleId: this.selRole.roleId,
        enterpriseCode: this.selRole.enterpriseCode,
        web_menu_list: list
      })
      this.selRole.web_menu_list = list
      this.$lgh.notifySuccess('【web菜单】修改成功', this.selRole.roleName + 'web菜单权限信息已修改陈工')
    },
    async saveAppMenu() {
      var list = this.$refs.appMenuTree.getCheckedKeys()
      await this.$lgh.post('api/enterprise/role/update_appmenu', {
        roleId: this.selRole.roleId,
        enterpriseCode: this.selRole.enterpriseCode,
        app_menu_list: list
      })
      this.selRole.app_menu_list = list
      this.$lgh.notifySuccess('【app菜单】修改成功', this.selRole.roleName + 'app菜单权限信息已修改陈工')
    }
  }
}
</script>
